<template>
	<div class="input-container">
		<input class="curved-border" type="text" :placeholder="languageStrings.zipcode" v-model="zipcode" />
		<input
			class="curved-border"
			type="text"
			:placeholder="languageStrings.confirmZipcode"
			v-model="confirmZipcode"
		/>
		<div
			id="disabled-button-container"
			v-if="(!zipcode.length > 0 && !confirmZipcode.length > 0) || zipcode !== confirmZipcode"
		>
			<button class="btn curved-border disabled-button">
				{{ languageStrings.noMatches }}
			</button>
		</div>
		<div v-else class="encore-style-button-container">
			<button
				class="btn curved-border encore-style-button"
				:title="
					(!zipcode.length > 0 && !confirmZipcode.length > 0) || zipcode !== confirmZipcode
						? languageStrings.zipCodesMustMatch
						: languageStrings.continueText
				"
				@click="submitToParent()"
			>
				{{ languageStrings.continueText }}
			</button>
		</div>
		<small v-if="(!zipcode.length > 0 && !confirmZipcode.length > 0) || zipcode !== confirmZipcode" class="warn">
			{{ languageStrings.zipCodesMustMatch }}
		</small>
	</div>
</template>

<script>
export default {
	name: "ZipcodeForm",
	props: {
		serverRules: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	components: {},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			zipcode: "",
			confirmZipcode: "",
			newAccountProperty: {
				propertyName: "",
				propertyValue: "",
				componentName: "ZipcodeForm",
			},
		};
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		submitToParent() {
			if (!this.zipcode && !this.confirmZipcode) {
				this.status.ok = false;
				this.status.message = languageErrorStrings.zipCodeFieldsDoNotMatch;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			this.newAccountProperty.propertyName = "addressZipCode";
			this.newAccountProperty.propertyValue = this.zipcode;
			this.eventBus.emit("addNewAccountProperty", this.newAccountProperty);

			this.eventBus.emit("advanceComponent");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.input-container {
	display: flex;
	flex-direction: column;
	margin: 15px auto;
	padding: 15px;
	max-width: 30em;
}

input {
	font-size: 1.5em;
	border-radius: 12px;
	color: #6305c9;
}

small {
	font-size: 1rem;
}

button.btn:disabled {
	background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(19, 1, 1, 0.3)) !important;
	color: light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3)) !important;
	border-color: light-dark(rgba(118, 118, 118, 0.3), rgba(195, 195, 195, 0.3)) !important;
}

.encore-style-button-container {
	margin-top: 2vh;
	margin-bottom: 1vh;
}
.curved-border {
	height: 3.5vh;
}

#disabled-button-container {
	background: red;
	border-radius: 15px;
	width: 20vw;
	margin-left: auto;
	margin-right: auto;
	font-size: 13px;
	margin-top: 2vh;
	margin-bottom: 1vh;

	.btn {
		transform: none;
		box-shadow: none;
	}
}

.disabled-button {
	color: white;
	background-clip: text;
	margin: auto;
	width: 20vw;
	height: 4.5vh;
	font-weight: 900;
	overflow-wrap: break-word;
}

/* .btn {
	height: 4.5vh;
} */
@media (max-width: 767px) {
	#disabled-button-container {
		width: 39vw;
	}

	.disabled-button {
		width: 39vw;
	}
}
</style>
